import React from 'react'
import { graphql } from 'gatsby'

import {
  AvatarBox,
  Avatar,
  Wrapper,
  Spacer,
  LinkBase,
  Name,
  Container,
} from '../components/atoms'

import { Flex } from 'theme-ui'
import { Box } from 'rebass'

import { HeadingBase as Heading, TextBase as Text } from '../components/blocks'

import Layout from '../components/layout'
import PortableText from '../components/portableText'

import styled from '@emotion/styled'

const BodyWrapper = styled(Box)`
  p {
    line-height: 1.5;
    font-size:1.25rem;
  }
`

export const query = graphql`
  query CaseStudyTemplateQuery($id: String!) {
    caseStudy: sanityCaseStudy(id: { eq: $id }) {
      id
      title
      _rawBody
      categories {
        title
      }
    }
  }
`

export const CaseStudyTemplate = (props) => {
  const { data, errors } = props
  const post = data && data.caseStudy
  return (
    <Layout>
      <Container>
        <Spacer>
          {post && (
            <Flex>
              <Box width={[1, 1, 7 / 12]} ml={4} pr={4}>
                <Heading variant="text.pageTitle">{post.title}</Heading>
                <BodyWrapper pt={3}>
                  <PortableText blocks={post._rawBody} />
                </BodyWrapper>
              </Box>
              <Box width={[1, 1, 4 / 12]} ml={4}>
                <Text color="#666" fontSize={1} mb={2}>What we did</Text>
                {post.categories && post.categories.length > 0 && (
                  <ul>
                    {post.categories.map((c) => (
                      <li><Text>{c.title}</Text></li>
                    ))}
                  </ul>
                )}
              </Box>
            </Flex>
          )}
        </Spacer>
      </Container>
    </Layout>
  )
}

export default CaseStudyTemplate
